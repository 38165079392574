@import "~antd/dist/antd.less";

.steps-content {
  margin-top: 16px;
  border: 1px dashed #e9e9e9;
  border-radius: 2px;
  background-color: #fafafa;
  min-height: 200px;
  text-align: left;
  padding: 24px;
}

.steps-action {
  margin-top: 24px;
}

.ant-checkbox-group-item {
  display: block;
  margin-right: 0;
  padding: 10px;
  text-align: left;
}

@primary-color: #F44336;@layout-header-background: #9E9E9E;@site-page-header: #9E9E9E;@finish-icon-color: #4CAF50;@finish-tail-color: #4CAF50;